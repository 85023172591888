//背景色
$bg-color: #f7f8fa;
// 主题色
$color-blue: #2962ff;
$color-blue2: #eef2ff;
$color-green: #2cf2d1;
$color-orange: #fdc62e;
$color-grey: #ebedf0; //分割线
$color-white: #fff;
//其他色值
$black-1: #101734;
$black-2: #272e42;
$grey-1: #606269;
$grey-2: #90939d;
$grey-3: #acaeb4;
$grey-4: #eef0f4;
$grey-5: #5e6576;
$grey-6: #b9bcc5;
$grey-7: #d4d7de;
$grey-8: #acaeb4;
$red: #f75a5a;
$red-prompt: #f16272;

$blue-0: #2357e8;
$blue-1: #1685ec;
$blue-2: #6991cd;
$blue-3: #b7caff;
$blue-4: #eaefff;
$gray-bg1: #f0f1f3;
$gray-bg2: #f7f8f9;
$gray-bg3: #fafafa;
$gray-bg4: #eceff3;
$gray-bg5: #e5e5e5;
$gray-bg6: #f7f8fa;
$img-path: '';
%position-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

%text-hide {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
}

%flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

%flex-items-center {
    display: flex;
    align-items: center;
}
%flex-vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
%flex-column {
    display: flex;
    flex-direction: column;
}

%flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin flex-center($row: center, $column: center) {
    display: flex;
    justify-content: $row;
    align-items: $column;
}
@mixin icon-font-size($f) {
    font-size: $f + px;
    line-height: $f + px;
}
@mixin font-size($f, $w: 400) {
    font-family: PingFang SC, PingFangSC-Regular;
    font-size: $f + px;
    font-weight: $w;
}

@mixin font-size-medium($s, $w: 500) {
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: $s + px;
    font-weight: $w;
}
@mixin width-hight($w, $h) {
    width: $w + px;
    height: $h + px;
}
@mixin text-ellipsis($line: 2) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}
@mixin radius-border($radius, $color: $color-grey) {
    border: 1px solid $color;
    border-radius: $radius + px;
}

@mixin fontSize($f, $w: 400) {
    font-family: 'PingFangSC-Regular,PingFang SC';
    font-size: $f + px;
    font-weight: $w;
}

@mixin fontSizeMedium($f, $w: 400) {
    font-family: 'PingFangSC-Medium,PingFang SC;';
    font-size: $f + px;
    font-weight: $w;
}

@mixin iconfontSize($f) {
    font-size: $f + px;
    line-height: $f + px;
}

@mixin widthHight($w, $h) {
    width: $w + px;
    height: $h + px;
}

//文字几行超出省略
@mixin multiTextHide($num) {
    display: -webkit-box;
    display: -moz-box;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    /* autoprefixer: on */
    -webkit-line-clamp: $num;
    -moz-line-clamp: $num;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin radiusBorder($radius, $color: $txt-gray) {
    border: 1px solid $color;
    border-radius: $radius + px;
}

.ant-layout-header {
    padding: 0 0 !important;
}
.ant-layout-sider {
    background-color: $color-white !important;
}

.layout-container {
    width: 100%;
    height: 100vh;
    min-width: 1280px;
}
.layout-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    z-index: 100;
    @include font-size(16, 400);

    .left {
        flex: none;
        width: 180px;
        @extend %flex-vertical-center;
        .logo {
            width: 98px;
            height: 20px;
        }
    }
    .center {
        flex: auto;
        display: flex;
        .menu {
            display: block;
            width: 112px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            color: $blue-3;
            cursor: pointer;
            &:hover,
            &.active {
                font-weight: 500;
                color: $color-white;
                background-color: $blue-0;
            }
        }
    }
    .right {
        flex: none;
        @extend %flex-items-center;
        color: $color-white;
        font-size: 13px;
        .avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-left: 20px;
            margin-right: 8px;
        }
        .dropout {
            padding-left: 28px;
            padding-right: 32px;
            cursor: pointer;
        }
    }
}
.layout-sidebox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 180px;
    height: 100%;
}

.layout-sidenav {
    padding: 24px 0;
    flex: auto;
    overflow-y: auto;

    .menu {
        width: 180px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        padding-left: 12px;
        display: flex;
        @include font-size(14, 400);
        color: $grey-1;

        &:hover,
        &.active {
            color: $color-blue;
            background-color: $blue-4;
        }
        &:hover .iconfont,
        &.active .iconfont {
            color: $color-blue;
        }
    }
    .menu-left {
        @extend %flex-vertical-center;
    }

    .nav-icon {
        color: $grey-1;
        font-size: 18px;
        margin-right: 8px;

        &.nav-icon-active {
            color: $color-blue;
        }
    }
    .icon-box {
        width: 18px;
        height: auto;
    }
    .icon-open,
    .icon-retract {
        font-size: 12px;
    }

    .third-menu {
        transition: all ease-in-out 0.2s;
        overflow: hidden;
        .menu {
            padding-left: 57px;
        }
    }
}
.layout-content {
    position: relative;
    overflow-y: auto;
    padding: 24px;
    width: calc(100% - 180px);
    height: 100%;
}
.layout-tabsbox {
    flex: none;
    margin: 24px auto;
    position: relative;
    @include font-size(14);

    .tabs {
        color: $black-1;

        border-radius: 4px;
        background-color: $bg-color;
    }
    .tabs-item {
        width: 156px;
        height: 40px;
        @extend %flex-vertical-center;
    }
    .tabs-name {
        margin: 0 8px;
    }
    .tabs-img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 4px;
    }

    .tabs-list {
        position: absolute;
        bottom: 0px;
        left: 170px;
        width: 220px;
        padding-top: 8px;
        background-color: $color-white;
        box-shadow: 0px 0px 10px #d4d7de;
        border-radius: 4px;
        z-index: 9999;
        display: flex;
        flex-direction: column;
    }

    .tabs-list-item {
        width: 220px;
        height: 40px;
        @extend %flex-items-center;
        padding-left: 24px;
        cursor: pointer;

        &:hover,
        &.active {
            background-color: $blue-4;
        }
    }

    .tabs-list-des {
        padding: 14px 24px;
        color: $grey-1;
        border-top: 1px solid $color-grey;
    }
}

.point-box {
    @extend %flex-vertical-center;
    width: 26px;
    height: 26px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    background-color: transparent;
    &:hover,
    &:active {
        background-color: $blue-4;
        transition: all ease-in-out 0.15s;
    }
    .icon-gengduo {
        color: $grey-8;
    }
    &:hover .icon-point,
    &:active .icon-point {
        color: $color-blue;
    }
}
.point-active {
    background-color: $blue-4;
    .icon-point {
        color: $color-blue;
    }
}
